import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import { API_BASE_URLS } from "../../utils/constant";
import { OrderDataType, OrderDetailsDataType } from "../../@customTypes/order";
import axiosInstance from "../../utils/axios";

type OrderState = {
  isLoading: boolean;
  error: boolean;
  success: boolean;
  totalOrders: null | number;
  orderList: OrderDataType[];
  selectedOrder: OrderDataType | null;
  productDetailsInOrder: OrderDetailsDataType | null;
  status: number | null;
  statusCounts: any;
};

const initialState: OrderState = {
  isLoading: false,
  error: false,
  success: false,
  orderList: [],
  selectedOrder: null,
  productDetailsInOrder: null,
  totalOrders: null,
  status: null,
  statusCounts: {}
};

const slice = createSlice({
  name: "order",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // IS SUCCESS
    isSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },
    // ORDER LISTING
    getOrderListSuccess(state, action) {
      state.isLoading = false;
      state.totalOrders = action.payload?.totalCount;
      state.orderList = action.payload?.orders;
      state.status = action.payload?.status;
      state.statusCounts = action.payload?.statusCounts;
    },
    getSelectedOrderSuccess(state, action) {
      state.isLoading = false;
      state.selectedOrder = action.payload;
    },
    removeSelectedOrder(state) {
      state.isLoading = false;
      state.selectedOrder = null;
    },
    getOrderDetailsSuccess(state, action) {
      state.isLoading = false;
      state.productDetailsInOrder = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function addOrder({
  companyId,
  payload
}: {
  companyId: number;
  payload: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.order}/create`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else {
        return response.data;
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getOrderById({
  companyId,
  orderId
}: {
  companyId: number;
  orderId: number;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.order}/${orderId}`
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else dispatch(slice.actions.getSelectedOrderSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getDraftOrder({
  companyId,
  customer_id
}: {
  companyId: number;
  customer_id: number;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.order}/draft`,
        { customer_id: customer_id }
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else {
        return response.data;
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getOrderList({
  companyId,
  payload
}: {
  companyId: number;
  payload: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.order}/list`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else {
        dispatch(slice.actions.removeSelectedOrder());
        dispatch(slice.actions.getOrderListSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function deleteOrder({
  companyId,
  id
}: {
  companyId: number;
  id: number;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.delete(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.order}/${id}/delete`
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function editOrder({
  companyId,
  payload
}: {
  companyId: number;
  payload: any;
}) {
  const { id, ...restPayload } = payload;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.order}/${id}/edit`,
        restPayload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addToCart({
  companyId,
  orderId,
  payload
}: {
  companyId: number;
  orderId: number;
  payload: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.order}/${orderId}/add-to-cart`,
        payload
      );
      // dispatch(getDraftOrder({ companyId: companyId, customer_id: custom }));
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addProductsToCart({
  companyId,
  orderId,
  payload
}: {
  companyId: number;
  orderId: number;
  payload: any;
}) {
  const { id, ...restPayload } = payload;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.order}/${orderId}${API_BASE_URLS.orderDetailsEdit}`,
        restPayload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function deleteProductsFromCart({
  companyId,
  orderId,
  payload
}: {
  companyId: number;
  orderId: number;
  payload: any;
}) {
  const { product_id, ...restPayload } = payload;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.delete(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.order
        }/${orderId}${API_BASE_URLS.orderDetailsDelete}/${product_id || ""}`,
        restPayload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function updateProductsQuantityInCart({
  companyId,
  orderId,
  payload
}: {
  companyId: number;
  orderId: number;
  payload: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.order}/${orderId}/details/edit`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}
export function updateProductsPriceInCart({
  companyId,
  productId,
  payload
}: {
  companyId: number;
  productId: number;
  payload: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/${productId}${API_BASE_URLS.price}/add`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getAllCartProducts({
  companyId,
  orderId,
  payload
}: {
  companyId: number;
  orderId: number;
  payload: any;
}) {
  const { id, ...restPayload } = payload;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.order}${orderId ? `/${orderId}` : ""
        }${API_BASE_URLS.orderDetailsList}`,
        restPayload
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else dispatch(slice.actions.getOrderDetailsSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}
export function getInvoice({
  companyId,
  id
}: {
  companyId: number;
  id: number;
}) {
  // const { id, ...restPayload } = payload;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.order}/${id}/invoice_pdf`
      );
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function updateOrderNotes({
  companyId,
  orderId,
  payload
}: {
  companyId: number;
  orderId: number;
  payload: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.order}/${orderId}/edit`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function startLoading() {
  return async () => dispatch(startLoading);
}

export function stopLoading() {
  return async () => dispatch(stopLoading);
}

export function removeError() {
  return async () => dispatch(slice.actions.hasError(false));
}

export function setSuccess(payload: boolean) {
  return async () => dispatch(slice.actions.isSuccess(payload));
}
export function removeSelectedOrder() {
  return async () => dispatch(slice.actions.removeSelectedOrder);
}
